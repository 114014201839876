import React from "react"

export default function PrivacyPolicy() {
  return (
    <div className="container">
      <div className="row">
        <div className="col text-center">
          <h1 className="mt-3">Wine Racks Privacy Policy</h1>

          <p>
            All data is stored in your iCloud via CloudKit. We do not collect
            any of your personal information.
          </p>
        </div>
      </div>
    </div>
  )
}
